export default {
  meta: {
    title: `Simon Martineau: Front-end web developer in the Greater Montréal`,
    description: `Remote front-end web developer experienced with Vue.js, React, NodeJS, GraphQL, Wordpress and others. Web development enthusiast of {years} years. Also a web engineer.`,
    notFound: `Page Not Found`,
    notFoundDescription: `Sorry! The page you are looking for does not exist. Visit the {homeLink} instead.`
  },
  navigation: {
    switchLangText: `Français`,
    switchLangLink: `/`,
    show: `Show navigation`,
    hide: `Hide navigation`,
  },
  footer: {
    copy: `© {year}, Simon Martineau. All rights reserved.`,
    credits: `Photo credits: {photoAuthorLink} on {unsplashLink}.`,
  },
  home: {
    hero: {
      heading: `Front\u2011end web <nobr>developer experienced</nobr><block><nobr>with {skill}</nobr></block>`,
      subheading: `in the <nobr>Greater Montreal region.</nobr>`
    },
    about: {
      heading: `About`,
      id: `about`,
      text: `<p>I have been a web development enthusiast for {years} years. Over time, I had the opportunity to work with multiple technologies and methodologies of the field for my projects. In part, these experiences have allowed me to feel at ease in most client and server environments. As a consequence of my desire to learn more and more, I keep discovering new things and redefining what I know.</p><p>I was introduced to the web via my interest in graphic design and digital tools. I was able to combine my ambitions through front-end development. Then, I specialized in programming. In short, I am also a web engineer.</p>`,
      linkText: `Know more`,
    },
    projects: {
      heading: `Projects`,
      id: `projects`,
    },
    network: {
      heading: `Network`,
      id: `network`,
      text: `<p>I offer web services to people in need of remote front-end expertise. I am already collaborating with those businesses in order to help them achieve their web development projects.</p>`,
      ctaText: `I am always ready to undertake <nobr>new collaborations!</nobr>`,
      ctaActionText: `Discuss`,
    }
  },
  projects: {
    collaborationWith: `Collaboration with`,
    close: `Close details`,
    tags: {
      fullstack: `Full Stack`,
      wordpress: `Wordpress`,
      integration: `Integration`,
    }
  },
  glossary: {
    homePage: `home page`
  }
}
