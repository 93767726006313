import React from 'react'

import { useTranslation } from '../utils'

const Footer = () => {
  const t = useTranslation()

  const currentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <footer className="c-footer  c-section">
      <div className="c-section__align">
        <div className="c-section__wrapper">
          <div className="c-section__content">
            <div className="ctx-content">
              <p>
                {t('footer.copy', {
                  year: currentYear(),
                })}
              </p>
              <p>
                {t('footer.credits', {
                  photoAuthorLink: <a key="Markus Spiske" href="https://unsplash.com/@markusspiske">Markus Spiske</a>,
                  unsplashLink: <a key="Unsplash" href="https://unsplash.com/">Unsplash</a>,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer