import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { usePrevious } from '../utils'

/**
 * 
 * @param {string} [props.tag] - The tag of the root element 
 * @param {boolean} [props.active] - The active state. Usually if the element is visible or not
 * @param {number} [props.timeout] - The amount of time before transition timeout
 */
const DisplayTransition = ({ tag: Element, active, timeout, className, children }) => {
  const [showActiveClass, setShowActiveClass] = useState(active)
  const [transitioning, setTransitioning] = useState(false)

  const prevActive = usePrevious(active)

  const timeoutRef = useRef()

  const stopTransition = () => {
    setTransitioning(false)
    clearTimeout(timeoutRef.current)
  }

  useLayoutEffect(() => {
    // Do not run on first render
    if (prevActive != null) {
      // Clear current transition if any
      stopTransition()

      // Mark as transitioning
      setTransitioning(true)

      // Trigger transition start after tiny delay
      setTimeout(() => setShowActiveClass(active), 10)

      // Timeout transition
      timeoutRef.current = setTimeout(() => setTransitioning(false), timeout)
    }
  }, [active, timeout])

  const handleTransitionEnd = () => {
    stopTransition()
  }

  return (
    <Element
      className={classnames(className, { 'is-active': showActiveClass, 'is-transitioning': transitioning })}
      onTransitionEnd={handleTransitionEnd}
    >
      {children}
    </Element>
  )
}

DisplayTransition.propTypes = {
  tag: PropTypes.string,
  active: PropTypes.bool,
  timeout: PropTypes.number
}

DisplayTransition.defaultProps = {
  tag: 'div',
  active: false,
  timeout: 500
}

export default DisplayTransition