import flatten from 'flat'

import en from './en'
import fr from './fr'

if (!Intl.NumberFormat) {
  require('@formatjs/intl-numberformat/polyfill')
  require('@formatjs/intl-numberformat/dist/locale-data/en')
  require('@formatjs/intl-numberformat/dist/locale-data/fr')
}

export const locales = ['fr', 'en']

export const defaultLocale = 'fr'

const messages = {
  en,
  fr,
}

// Flatten messages for react-intl
for (let lang in messages) {
  messages[lang] = flatten(messages[lang]);
}

export { messages }
