import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { IntlProvider } from 'react-intl';

// Import styles
import '../scss/main.scss'

// Import vendors setup
import '../vendors'

import { useEventListener, checkKeyCode, useLocale } from '../utils'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const Layout = ({ children, i18nMessages }) => {
  const [tabbing, setTabbing] = useState(false);
  const [navActive, setNavActive] = useState(false);

  const { locale, defaultLocale } = useLocale();

  // Add is-tabbing class when user is using tab key

  useEventListener(global.document && document.body, 'keydown', (event) => {
    if (checkKeyCode(event, 'Tab')) {
      setTabbing(true)
    }
  })

  useEffect(() => {
    if (global.document) {
      if (tabbing) {
        document.body.classList.add('is-tabbing')
      } else {
        document.body.classList.remove('is-tabbing')
      }
    }
  }, [tabbing])

  const handleNavUpdateActive = (value) => {
    setNavActive(value);
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={i18nMessages}
      onError={(err) => {
        // Prevent missing translation logging
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
    >
      <div className={classnames('l-wrapper')}>
        <Navigation active={navActive} onUpdateActive={handleNavUpdateActive} />
        <main className={classnames('l-main', { 'nav-is-active': navActive })}>
          {children}
          <Footer/>
        </main>
      </div>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
