import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import classnames from 'classnames'
import { useEventListener } from '../utils'

const HeadingLine = ({ className, ...attrs }) => {
  const [windowHeight, setWindowHeight] = useState(0)
  const [elementHeight, setElementHeight] = useState(0)
  const [elementTop, setElementTop] = useState(0)

  const ref = useRef()

  const start = elementTop - windowHeight
  const middle = start + ((windowHeight + elementHeight) / 2)
  const end = elementTop + elementHeight

  const { scrollY } = useViewportScroll()
  const translateX = useTransform(scrollY, [start, middle, end], [0, 20, 50])
  const scaleX = useTransform(scrollY, [start, middle, end], [1, 0.7, 1])

  const handleViewportResize = () => {
    if (global.window) {
      const { top, height } = ref.current.getBoundingClientRect() || {}

      setWindowHeight(window.innerHeight)
      setElementHeight(height)
      setElementTop(top + window.scrollY)
    }
  }

  useLayoutEffect(() => {
    handleViewportResize()
  }, [])

  useEventListener(global.window, 'resize', handleViewportResize)
  useEventListener(global.window, 'orientationchange', handleViewportResize)

  return (
    <motion.span ref={ref} className={classnames(className, 'c-section-heading__line')} style={{ translateX, scaleX }} {...attrs} />
  )
}

export default HeadingLine