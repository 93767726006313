export default {
  meta: {
    title: `Simon Martineau : Développeur web front-end dans le Grand Montréal`,
    description: `Développeur web front-end à distance qui travaille avec Vue.js, React, NodeJS, GraphQL, Wordpress et autres. Passionné de développement web depuis maintenant {years} ans. Intégrateur et ingénieur web.`,
    notFound: `Page introuvable`,
    notFoundDescription: `Désolé! La page que vous recherchez n'existe pas. Visitez la {homeLink} à la place.`
  },
  navigation: {
    switchLangText: `English`,
    switchLangLink: `/en/`,
    show: `Afficher la navigation`,
    hide: `Cacher la navigation`,
  },
  footer: {
    copy: `© {year}, Simon Martineau. Tous droits réservés.`,
    credits: `Crédits photo: {photoAuthorLink} sur {unsplashLink}.`,
  },
  home: {
    hero: {
      heading: `Développeur web <nobr>front\u2011end qui travaille</nobr><block><nobr>avec {skill}</nobr></block>`,
      subheading: `dans la grande région <nobr>de Montréal</nobr>.`
    },
    about: {
      heading: `À propos`,
      id: `a-propos`,
      text: `<p>Je suis un passionné de développement web depuis maintenant {years} ans. Au fil du temps, j'ai eu la chance de toucher à une multitude de technologies et méthodologies du domaine dans le cadre de mes projets. Ces expériences me permettent, entre autres, de me sentir à l'aise dans la plupart des environnements client et serveur. Dû à mon désir d'en apprendre toujours plus, je continue de découvrir de nouvelles choses et de remettre en question mon savoir.</p><p>J'ai été introduit au web à travers mon intérêt pour le design graphique et le numérique. J'ai été en mesure de combiner mes ambitions par le développement front-end. Par la suite, je me suis spécialisé en programmation. Bref, je suis intégrateur et ingénieur web.</p>`,
      linkText: `En savoir plus`,
    },
    projects: {
      heading: `Projets`,
      id: `projets`,
    },
    network: {
      heading: `Réseau`,
      id: `reseau`,
      text: `<p>J'offre mes services à tous ceux et celles qui nécessitent une expertise front-end dans un cadre de télétravail. Je collabore déjà avec ces entreprises dans le but de les aider à réaliser leurs projets de développement web.</p>`,
      ctaText: `Je suis toujours prêt à entreprendre de <nobr>nouvelles collaborations!</nobr>`,
      ctaActionText: `Discuter`,
    }
  },
  projects: {
    collaborationWith: `Collaboration avec`,
    close: `Fermer les détails`,
    tags: {
      fullstack: `Full Stack`,
      wordpress: `Wordpress`,
      integration: `Intégration`,
    }
  },
  glossary: {
    homePage: `page d'accueil`
  }
}
