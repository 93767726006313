import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { motion } from 'framer-motion'

import { useTranslation, useLocale, useInitialReveal } from '../utils'
import DisplayTransition from './DisplayTransition'

const variants = {
  hidden: {
    opacity: 0,
    x: -100,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut'
    }
  }
}

const Navigation = ({ active, onUpdateActive }) => {
  const reveal = useInitialReveal()

  const { homeUrl } = useLocale();
  const t = useTranslation()

  const handleToggleClick = (event) => {
    event.preventDefault()
    onUpdateActive(!active)
  }

  useEffect(() => {
    if (global.document) {
      if (active) {
        document.body.classList.add('no-scroll-mobile')
      } else {
        document.body.classList.remove('no-scroll-mobile')
      }
    }
  }, [active])

  return (
    <motion.nav
      className={classnames('c-nav', { 'is-active': active })}
      initial="hidden"
      animate={reveal ? 'visible' : 'hidden'}
      variants={variants}
    >
      <a
        href="#navigation"
        className={classnames('c-nav__toggle  c-menu-toggle', { 'is-active': active })}
        title={active ? t('navigation.hide') : t('navigation.show')}
        onClick={handleToggleClick}
      >
        <span className="c-menu-toggle__bar"></span>
        <span className="c-menu-toggle__bar"></span>
      </a>
      <DisplayTransition
        id="navigation"
        className="c-nav__wrapper"
        active={active}
      >
        <Link to="/" className="c-nav__brand">
          <img src={`../../images/simon-martineau-logo.svg`} alt="Simon Martineau" className="c-nav__brand-link"/>
        </Link>

        <ul className="c-nav__list">
          <li className="c-nav__item">
            <AnchorLink to={`${homeUrl}#${t('home.about.id')}`} className="c-nav__link">{t('home.about.heading')}</AnchorLink>
          </li>
          <li className="c-nav__item">
            <AnchorLink to={`${homeUrl}#${t('home.projects.id')}`} className="c-nav__link">{t('home.projects.heading')}</AnchorLink>
          </li>
          <li className="c-nav__item">
            <AnchorLink to={`${homeUrl}#${t('home.network.id')}`} className="c-nav__link">{t('home.network.heading')}</AnchorLink>
          </li>
          <li className="c-nav__item">
            <Link to={t('navigation.switchLangLink')} className="c-nav__link">{t('navigation.switchLangText')}</Link>
          </li>
        </ul>
      </DisplayTransition>
    </motion.nav>
  )
}

Navigation.propTypes = {
  active: PropTypes.bool,
  onUpdateActive: PropTypes.func,
}

Navigation.defaultProps = {
  active: false,
  onUpdateActive: () => {},
}

export default Navigation