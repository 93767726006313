import React, { useRef, useState, useLayoutEffect } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import classnames from 'classnames'
import { useEventListener } from '../utils'

const velocity = 0.3;

const BackText = ({ className, children, parallaxOffset, 'aria-hidden': ariaHidden, ...attrs }) => {
  const [elementTop, setElementTop] = useState(0)

  const start = elementTop - parallaxOffset

  const ref = useRef()

  const { scrollY } = useViewportScroll()
  const translateY = useTransform(scrollY, [start, start + 1], [0, velocity], {
    clamp: false
  })

  const handleViewportResize = () => {
    if (global.window) {
      const { top } = ref.current.getBoundingClientRect() || {}

      setElementTop(top + window.scrollY)
    }
  }

  useLayoutEffect(() => {
    handleViewportResize()
  }, [])

  useEventListener(global.window, 'resize', handleViewportResize)
  useEventListener(global.window, 'orientationchange', handleViewportResize)

  return (
    <span ref={ref} className={classnames(className, 'c-back-text')}>
      <motion.span
        className={classnames('c-back-text__text')}
        aria-hidden={ariaHidden}
        style={{ translateY }}
        {...attrs}
      >
        {children}
      </motion.span>
    </span>
  )
}

BackText.defaultProps = {
  parallaxOffset: 0,
  'aria-hidden': 'true',
}

export default BackText