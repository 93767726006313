import { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentLangKey } from 'ptz-i18n'
import { useLocation } from '@reach/router'

import { locales, defaultLocale } from './lang'

import rich from './components/rich'

/**
 * usePrevious Hook.
 * See https://usehooks.com/usePrevious/
 *
 * @param {*} value 
 */
export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value])

  // Return previous value (happens before update in useEffect above)
  return ref.current
}


/**
 * useMounted hook
 */
export const useMounted = () => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
  }, [])

  return mounted.current
}

/**
 * Check if a KeyboardEvent's key code corresponds to the provided code.
 *
 * @param {KeyboardEvent} event
 * @param {...string} code - The code(s)
 */
export const checkKeyCode = (event, ...codes) => {
  // Add supported keyCodes here
  const keyCodes = {
    Tab: 9,
    Enter: 13,
    Space: 32
  }

  if (event) {
    // Use code when available
    if (event.code != null) {
      return codes.includes(event.code)
    // Fallback to keyCode for unsupported browsers
    } else if (event.keyCode != null) {
      return codes.map(code => keyCodes[code]).includes(event.keyCode)
    }
  }

  return false
}

/**
 * useEventListener hook
 * https://usehooks.com/useEventListener/
 *
 * @param {HTMLElement|Window} target - The listenable target
 * @param {string} eventType
 * @param {function} listener
 * @param {array} [dependencies] - Listener dependencies
 */
export const useEventListener = (target, eventType, listener) => {
  if (typeof listener !== 'function') {
    throw new Error('listener is not a function')
  }

  const listenerRef = useRef(listener)

  useEffect(() => {
    listenerRef.current = listener
  }, [listener])

  useEffect(() => {
    if (target) {
      const callListener = (...args) => listenerRef.current(...args)

      if (typeof target.addEventListener === 'function') {
        target.addEventListener(eventType, callListener)
      }

      return () => {
        if (typeof target.removeEventListener === 'function') {
          target.removeEventListener(eventType, callListener)
        }
      }
    }
  }, [])
}

/**
 * Get shorthand for intl.formatMessage
 * 
 * @returns {function}
 */
export const useTranslation = () => {
  const intl = useIntl();

  return (id, values = {}) => {
    return intl.formatMessage({ id }, {
      ...rich,
      ...values
    })
  }
}

/**
 * useLocale hook
 */
export const useLocale = () => {
  const location = useLocation();

  const locale = getCurrentLangKey(locales, defaultLocale, location.pathname);
  const homeUrl = `/${locale}/`.replace(`/${defaultLocale}/`, '/');

  return { locale, defaultLocale, locales, homeUrl }
}

export const useInitialReveal = (delay = 500) => {
  const [reveal, setReveal] = useState(false)

  useEffect(() => {
    setTimeout(() => setReveal(true), delay)
  })

  return reveal
}

export const dateRangeToYears = (start, end) => {
  return Math.floor((end - start) / 1000 / 60 / 60 / 24 / 365.25)
}
